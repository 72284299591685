<template>
  <Content class="sider_content">
    <Layout class="sider_layout">
      <Header class="sider_header">
        <div class="sider_logo">
          <img src="../assets/images/Icon.jpg" width="100" height="100">
        </div>
      </Header>
      <Layout>
        <Sider hide-trigger :style="{background: '#fff'}" class="sider_body">
          <Menu theme="light" width="auto"
                :active-name="$route.name" :open-names="['upload-video','manage-video']" accordion>
            <Submenu name="upload-video">
              <template slot="title">
                <Icon type="md-cloud-upload"/>
                发布视频
              </template>
              <MenuItem name="upload" to="/seller/video/upload">上传视频</MenuItem>
            </Submenu>
            <Submenu name="manage-video">
              <template slot="title">
                <Icon type="logo-youtube"/>
                内容管理
              </template>
              <MenuItem name="videoManage" to="/seller/video/manage">视频管理</MenuItem>
            </Submenu>
          </Menu>
        </Sider>
      </Layout>
    </Layout>
  </Content>
</template>

<script>
  export default {
    name: "sellerSider"
  }
</script>

<style scoped lang="less">
  .sider_content {
    float: left;

    .sider_layout {
      width: 200px;
      height: 100%;

      .sider_header {
        float: left;
        height: 206px;
        border-right: 1px solid #DCDEE2;

        .sider_logo {
          padding: 40px;
        }
      }

      .sider_body {
        float: left;
      }
    }
  }

  @media only screen and (min-width: 1920px) {
    .sider_content {
      height:823px;
    }
  }

  @media only screen and (min-width: 2560px) {
    .sider_content {
      height: 1080px;
    }
  }
</style>
